<template>
	<div>
		<div class="box">
           
          <div class="search">
              <img class="logo" src="../assets/logo.png" alt="" />
              <div class="sea">
                  <el-input style="	width: 680px; " v-model="input" placeholder="请输入内容"></el-input>
                  <div class="seabut" @click="serch">搜索</div>
              </div>
          </div>
		
         <div v-if="chooseList1.length>0">
             <div class="" style=" display: flex; align-items: center;margin-top: 87px;" v-for="(item,index) in chooseList1" :key="index" v-if="index==0"  @click="deatil(item.id,'搜索','search')">
                  <img style="width:240px;height: 133px;object-fit: cover;margin-right: 23px;" :src="item.image" alt="" >
                  <div>
                      <div class="building"  >
									
									<div>
                                        <!-- <span style="color:#0148C4">政策</span> | -->
										<div class="building-s1" style="width: 898px;">  {{item.title}}</div>
										<div  class="building-s2" style="width: 898px;">{{item.blurb}}</div>
										<div  class="building-s3">  <span>发布者：{{item.publisher}}</span> <span>{{item.data}}</span> </div>
									</div>
								</div>
                                
                  </div>

             </div>
             <div class="" style=" display: flex; align-items: center;margin-top: 87px;" v-for="(item,index) in chooseList1" :key="index" v-if="index>0" @click="deatil(item.id,'搜索','search')">
                 
                  <div>
                      <div class="building"  >
									
									<div>
                                        <!-- <span style="color:#0148C4">政策</span> |  -->
										<div class="building-s1" style="width: 1200px;">{{item.title}}</div>
										<div  class="building-s2" style="width: 1200px;">{{item.blurb}}</div>
										<div  class="building-s3">  <span>发布者：{{item.publisher}}</span> <span>{{item.data}}</span> </div>
									</div>
								</div>
                                
                  </div>

             </div>
             
         </div>
            
           <div v-else style="height: 100vh;">

		</div>
            
           
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
                chooseList1:[],
                 input: ''
			}
		},
		created() {

		},
		methods: {
            deatil(id,name,url){
				this.$router.push({
					path: '/deatil',
				 query: {detailsid: id , name:name , path:url }
				
				})
			},
               serch(){
          this.$https
			.get(`/api/pc/search?search=${this.input}`,)
			.then((res) => {
		console.log(res);
			this.chooseList1=res.data
			this.$message({
								
								offset: 80,
								message: res.msg
							});
							
			
        });
             }
		}
	}
</script>

<style scoped>
	.building-s1{
       
		width: 278px;
		height: 28px;
		font-size: 20px;
		font-weight: bold;
		color: #222222;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		margin-bottom: 16px;
		font-family: PingFang SC-Bold, PingFang SC;
	}
	/* .building-s1:hover{
		color: #0049C2;
	} */
	.building-s2{
		width: 268px;
		font-size: 16px;
		font-weight: 500;
		color: #666666;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-family: PingFang SC-Medium, PingFang SC;
		margin-bottom: 23px;
	}
	.building-s3{
		font-size: 16px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #999999;
        display: flex;
        justify-content: space-between;
			
	}
    .search{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
	.box {
		width: 1200px;
		margin: 56px auto;
       
   
	}
    .bot{
        display: flex;
        margin-top: 52px;
        
    }
   .logo {
		width: 164px;
		height: 50px;
		object-fit: cover;
	}
    .sea{
        display: flex;
        align-items: center;
    }
    .seabut{
        width: 120px;
        height: 64px;
        background: #0148C4;
        border-radius: 0px 10px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
   /deep/   .el-input__inner {
         
    -webkit-appearance: none;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    /* color: #606266; */
    display: inline-block;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    height: 64px;
    line-height: 64px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
}

</style>